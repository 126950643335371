<template>
  <div class="intellectual-point-live">
    <div class="app-container">
      <div class="header-top">
        <a href="https://rapid.education" class="logo-wrapper w-inline-block">
          <div class="logo-text">Digital Natives Live</div>
        </a>
        <div class="button-wrapper">
          <router-link to="/" class="button w-inline-block">
            <div class="button-gradient">
              <div class="button-modern">Exit To Home</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="main-wrapper">
        <div class="chatbox">
          <div class="chat-title">Live Chat</div>
            <presentation-chat style="height: 100%"/>
        </div>
        <div class="middle-speacer"></div>
        <div class="stream-iframe">
          <div class="html-embed w-embed w-iframe">
            <div style="padding:56.25% 0 0 0;position:relative;">
              <iframe src="https://player.osidex.stream/live/6092cfed44a727001245a710?autoplay=true" frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""
                      style="position:absolute;top:0;left:0;width:100%;height:100%;"
                      title="Digital Natives Meetup Live"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import PresentationChat from '@/views/PresentationChat';

export default {
  name: 'PresentationWatch',
  components: {
    PresentationChat,
  },
};
</script>
